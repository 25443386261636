<template>
    <div class="express-box">
      <nav-index :title="'物流信息'"/>
      <div class="express">
        <div class="card">
            <img :src="expCompany.imgUrl" alt="" width="55" height="55" v-show="expCompany.status" v-if="isExpress">
            <div class="experss-img" v-else>{{ expCompany.name }}</div>
            <div class="content" v-show="expCompany.status">
                <div class="status">{{ expCompany.status }}</div>
                <div class="trackingnumber" v-if="isExpress">{{ expCompany.name }} {{ trackingnumber }}</div>
                <div class="trackingnumber" v-else>{{ expCompany.name }}物流</div>
                <div class="company" v-show="isExpress">国内快递电话：{{ expCompany.phone }}</div>
            </div>
        </div>
        <ul class="progress_box" v-if="isExpress">
            <li v-for="(list,index) in lists" :key="list.id" 
                :style="{'border-left': index === 0 ? '1px solid #ec745b': ''}">
                <div class='step' v-if="index !== 0">
                    <p class='text'>{{ list.content  }}</p>
                    <p class='datetime'>{{ list.time  }}</p>
                </div>
                <div class='step' :class="[index === 0 ? 'last_progress' : '']" v-else>
                    <p class='text active'>{{ list.content  }}</p>
                    <p class='datetime active'>{{ list.time  }}</p>
                </div>
            </li>
        </ul>
        <div class="waybill-img" v-else>
            <img :src="trackingnumber" alt="" class="img">
        </div>
      </div>
    </div>
</template>

<script>
import NavIndex from '../../components/nav/index.vue'
import { queryRECERoutes, queryOrderById } from '@/api/api'
import list from '../../assets/json/logisticsList.json'

export default {
  name: 'Order',
  components: {
    NavIndex,
  },
  created(){
    this.getData()
  },
  mounted(){
    
  },
  data:()=>{
    return {
        lists:[],
        orderInfo:{},
        isExpress: true,  // 快递还是物流
        expCompany:{}, // 物流公司信息
        trackingnumber: '', // 快递单号
        userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0] : {}
    }
  },
  methods:{
    queryExpress(){
        this.trackingnumber = this.$route.query.waybill
        this.lists.length = 0
        if(this.trackingnumber){
            if(this.trackingnumber.includes('/public/images')){
                this.isExpress = false
                this.expCompany.phone = ''
                this.expCompany.status = '已发货'
                this.expCompany.imgUrl = ''
                for(let i = 0; i < list.logisticsList.length; i++){
                    if(this.orderInfo.expressCompany === list.logisticsList[i].id){
                        this.expCompany.name = list.logisticsList[i].name.split('物流')[0]
                    } else if(this.orderInfo.expressCompany.length === 0){
                        this.expCompany.name = '其他'
                    }
                }
            } else {
                this.isExpress = true
                let checkPhoneNo = this.$route.query.phoneNum ? this.$route.query.phoneNum.slice(-4) :  this.userInfo.phoneNum.slice(-4) // 优先使用订单的用户手机
                queryRECERoutes({ trackingNumber: this.trackingnumber, checkPhoneNo: checkPhoneNo }).then(res=>{
                    let resData = res.data.data
                    resData.data.forEach(item=>{
                        let obj = {}
                        obj.content = item.remark
                        obj.time = item.acceptTime
                        this.lists.push(obj)
                    })
                    this.expCompany.name = resData.expressCompany
                    this.expCompany.phone = resData.serviceTel
                    this.expCompany.status = resData.status
                    this.expCompany.imgUrl = resData.imgUrl
                })
            }
        } else {
            // 默认下单信息
            this.expCompany.phone = '暂无'
            this.expCompany.status = '待收揽'
            let list = {}
            list.id = '1'
            list.content = '下单成功，商家正在打包商品，请耐心等待'
            list.time = this.orderInfo.updatedAt
            this.lists.push(list)
        }
    },
    getData(){
      queryOrderById({ oid: this.$route.query.oid }).then(res=>{
        let resData = res.data.data
        this.orderInfo = {
            nickname: resData.nickname,
            phoneNumber: resData.phone_num,
            address: resData.address,
            imgUrl: resData.imgUrl,
            expressCompany: resData.express_company,
            waybill: resData.waybill,
            updatedAt: resData.updatedAt,
        }
        this.queryExpress()
      })
    },
  },
}
</script>

<style scoped lang="scss">
.express-box {
  min-height: 100vh;
  box-sizing: border-box;
  .express {
    padding: 55px 0 10px;
    .card {
        background: #fff;
        display: flex;
        align-items: center;
        margin: 15px;
        padding: 15px 0;
        border-radius: 5px;
        font-size: 14px;
        min-height: 92px;
        box-sizing: border-box;
        .experss-img {
            width: 55px;
            height: 55px;
            margin: 0 15px;
            background: rebeccapurple;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-weight: 600;
            font-size: 22px;
            border-radius: 5px;
        }
        img {
            width: 55px;
            padding: 0 15px;
        }
        .content {
            flex: 1;
            text-align: left;
            .company {
                margin: 2px 0;
            }
        }
    }
    .waybill-img {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
        .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
  }
}
* {
    margin: 0;
    padding: 0;
}

ul {
    margin:25px;
}

li {
    position: relative;
    padding: 0 21px;
    list-style: none;
    text-align: left;
    border-left: 1px solid #ddd;
}

ul > li{
    &:not(:first-child)::before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: -5px;
        width: 10px;
        height: 10px;
        text-align: center;
        background: #ddd;
        border-radius: 50%;
    }
}

li:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -5px;
    width: 10px;
    height: 10px;
    text-align: center;
    background: #ec745b;
    border-radius: 50%;
}
.last_progress{
    border: none;
}
.last_progress::before{
    display: table-cell;
    position: absolute;
    vertical-align: middle;
    left: -9px;
    top: -2px;
    width: 16px;
    height: 16px;
    line-height: 11.5px;
    text-align: center;
    background: #ec745b;
    color: #ec745b;
    font-size: 18px;
    border-radius: 50%;
}
.step{
    padding: 10px 0;
}
.text{
    font-size: 14px;
    color: #121212;
    font-weight: 100;
    margin-bottom: 3px;
    padding: 10px 0;
    min-height: 60px;
    box-sizing: border-box;
}
.datetime{
    color: #999999;
    font-size: 14px;
}
.active{
    color: #ec745b;
}
</style>